<template>
    
  <div class="row justify-center">
    <h4>Les parrainages</h4>
  </div>

  <div class="row justify-center">
    <div class="col-xs-10 col-md-6">

      <h5 class="indent">
        Pourquoi un parrainage et pas juste une candidature ?
      </h5>

      <div class="regular-text indent">
        Afin de conserver l’esprit de la communauté et une bonne entente entre les membres, Mandragore à décidé de mettre en place un système de parrainage. Nous avons choisi de permettre un contact plus complet qu’un simple CV, ce qui permet de rencontrer le joueur et pas simplement un personnage in game.<br><br>
        Initialement, il était possible d’intégrer la guilde uniquement si on connaissait un membre et qu’il décidait de nous faire entrer dans le cercle très fermé de Mandragore.<br><br>
        Seulement, deux constats ont été faits : 1) Pour pouvoir continuer à faire du contenu de qualité en jeu, il manquait parfois des joueurs partageant cette envie. 2) Mandragore passait probablement à côté de personnes très sympathiques. Il a donc été décidé d’ouvrir Mandragore à quiconque souhaitait nous rejoindre, tout en gardant le système de parrainage pour veiller à intégrer uniquement des personnes avec “l’esprit Mandragore”, les mêmes délires, le même humour, mais surtout l’envie de se faire des copains au delà du personnage in game.
      </div>

      <h5 class="indent">
        Comment fonctionne un parrainage ?
      </h5>

      <div class="regular-text indent">
        Un parrainage est un processus qui dure 3 mois. Il comporte 3 étapes : Le parrainé trouve un parrain/une marraine, il rencontre et joue avec les membres (sur Wow, discord ou n’importe quel autre jeu/canal), la guilde décide de manière collégiale si le courant passe ou pas.
      </div>

      <h5 class="indent">
        Trouver son parrain ou sa marraine :
      </h5>

      <div class="regular-text">
        <div class="indent">
          Pour trouver sa marraine/son parrain, il y a plusieurs possibilités :<br><br>
        </div>
        <ul>
          <li>
            Soit vous connaissez un membre qui souhaite vous parrainer et il peut donc créer un poste de parrainage et vous introduire. Vous aurez alors le rôle de Bourgeon.
          </li>
          <li>
            Soit vous ne connaissez personne, vous nous avez trouvé par hasard, sur un site de recrutement (wow progress, raider.io, warcraft log ou via l’outil de recherche de guilde ig) ou grâce à notre indétrônable réputation (c’est faux), il vous suffit de demander à être guildé en tant que Bourgeon. A partir de là, le processus de 3 mois débute, pendant lequel vous devez trouver au moins 1 personnes avec qui vous arrivez à créer un bon contact et qui décide de vous parrainer.
          </li>
        </ul>
      </div>

      <h5 class="indent">
        Le parrainage :
      </h5>

      <div class="regular-text">
        <div class="indent"><i style="text-decoration: underline;">Le poste de parrainage :</i></div>
        Lorsque le parrain est trouvé (que vous le connaissiez avant d’arriver ou pas), celui-ci va demander à un officier d’ouvrir un post de parrainage dans lequel il fera une présentation de son filleul : Qui c’est IRL et en jeu, qu'est-ce qu’il aime faire, pourquoi vous l’aimez bien, pourquoi vous pensez que les autres membres l’aimeront bien, est-ce qu’il prend ses recettes de cuisine sur Marmiton, etc… Le mieux est de la co-écrire afin qu’elle soit la plus juste et complète possible. Agrémentez le tout de photos du chat/chien du parrainé, pour plus de réussite. C’est ce post qui servira de support à la suite du parrainage et aux discussions des membres.
        <br><br>
        <div class="indent"><i style="text-decoration: underline;">Le rôle du filleul :</i></div>
        Vous êtes maintenant un fier Bourgeon ! Filez donc jouer et discuter avec les autres membres de la communauté, faites vous des amis, créez du lien, faites vous des souvenirs, amusez-vous, et surtout soyez vous mêmes, c’est comme ca qu’on vous aime &lt;3.
        <br><br>
        <div class="indent"><i style="text-decoration: underline;">Le rôle du parrain :</i></div>
        Vous voyez quand vous ramenez un nouvel ami dans un groupe bien établi ? Ben là, c’est pareil !
        Le parrain est la personne qui est censée connaître le mieux son filleul, il est là pour l’aider à s’intégrer avec les autres membres. Si jamais des remarques sont faites par des membres à propos de son filleul, c’est à lui de lui expliquer ce qui ne va pas avec tact et délicatesse, afin qu’il puisse éventuellement revoir sa posture. 
        Le parrain à aussi un rôle de mentor : Il doit présenter les mangroves existantes, les activités proposées, l’association, les IRL… Si vous manquez de connaissances sur un sujet en tant que parrain, pas de problème. Il y a plein d’autres membres qui seront ravis de vous éclairer !
      </div>


      <h5 class="indent">
        La décision des membres :
      </h5>

      <div class="regular-text">
        <div class="indent">
          Le post de parrainage sera accessible aux membres qui vont parler de l’expérience qu’il ont avec le filleul in game, en vocal, à l'écrit sur discord, à la maison (pour les plus intimes)... Ceux-ci vont ensuite pouvoir décider collégialement de s’ils pensent qu’il est en phase avec l’esprit Mandragore, et voter sur le parrainage.
          <br>
          Plusieurs types de votes sont possibles : 
        </div>
        <ul>
          <li>+2 : “Cette personne est absolument exceptionnelle, elle incarne l’esprit Mandragore !”</li>
          <li>+1 : “J’ai passé de bons moments avec cette personne, je valide son parrainage”</li>
          <li>-1 : “Je n’apprécie pas cette personne,  j’ai eu des échanges peu agréables avec elle”</li>
          <li>Veto : Dans le cas où le parrainage pose un problème, ou qu’une personne n’a vraiment pas l’esprit Mandragore,  un membre peut émettre un veto, dont il explicite les raisons.</li>
        </ul>
          <div>
            Dans tous les cas, un membre peut changer son vote quand il le souhaite tant que le parrainage n’est pas clôturé.
            <br>
            Au bout de 3 mois, les votes et avis sont comptabilisés : 
          </div>
          <ul>
            <li>Si le filleul reçoit plus de 15 votes en faveur de son intégration, il devient Mandragore et obtient le grade de Plante.</li>
            <li>S'il comptabilise seulement 8 votes, mais aucun vote négatif, il devient Feuille et peut quand même rester dans la guilde.</li>
          </ul>

      </div>

      <h5 class="indent">
        Qu’est-ce qu’il se passe à la fin du parrainage ?
      </h5>

      <div class="row">

        <div class="col-md-6 smaller-text" style="padding: 15px;">
          <div class="medium-text" style="display:flex; justify-content: center;">
            <i>Pour les plantes :</i>
          </div>
          <br>
          Félicitations, vous êtes maintenant une belle plante ! Toutes les réalités, toutes les dimensions vous sont ouvertes (ou presque) !<br>
          Vous pouvez maintenant accéder aux canaux restreints du discord (enfin presque tous les canaux restreints 🗑️).<br>
          Vous pouvez vous inscrire pour venir aux IRL (les IRL annuelles et les mini IRL entre deux).<br>
          Vous pouvez continuer à faire ce que vous aimez faire avec la communauté.<br>
          Vous pouvez vous la péter grave !<br>
          Vous pouvez aussi, si le cœur vous en dit, adhérer à l’association !<br>
          Si vous en avez l’envie, vous pouvez aussi proposer votre aide ou votre candidature pour adhérer à une mangrove et vous impliquer un peu (ou beaucoup) plus dans la vie de Mandragore, que ce soit IRL ou en jeu.<br><br>
        </div>

        <div class="col-md-6 smaller-text" style="padding: 15px;">
          <div class="medium-text" style="display:flex; justify-content: center;">
            <i>Pour les feuilles :</i>
          </div>
          <br>
          Vous êtes bien alignés avec l’esprit Mandragore, même si vous n’avez pas encore eu assez d’occasions d’échanger et de créer un lien avec des membres Mandragore pour l’instant.<br>
          Vous pouvez continuer à faire ce que vous aimez faire avec la guilde.<br>
          Vous pourrez à nouveau tenter le processus de parrainage quand le moment vous semblera opportun, si l’aventure vous tente toujours.<br>
          Vous avez accès à certains canaux restreints du discord.<br>
        </div>

      </div>

      
    </div>
  </div>

  <div class="row justify-center" style="margin-top: 40px; margin-bottom: 30px;">
    <div class="justify-center">

    <router-link clas="col-6" to="/association/mangroves">
      <q-btn color="primary" label="◄&nbsp;&nbsp;&nbsp;Les mangroves" />
    </router-link>

  </div>
</div>  

</template>  


<style scoped>

.indent {
  text-indent: 50px;
}

.smaller-text {
    font-size: 14px;
}

.medium-text {
  font-size: 15px;
}

</style>